import React from "react";
import { graphql } from "gatsby";
import Footer from "../../components/Layout/Footer";
import MediaCard from "../../components/Media/MediaCard";

export default function NewslettersPage({ data }) {
  const newsletters = data.allSanityNewsletter.nodes;

  return (
    <>
      <main
        className="site-container"
        style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
      >
        <h1
          style={{
            marginBottom: "2rem",
            fontSize: "2rem",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          Newsletters
        </h1>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
            gap: "4rem",
          }}
        >
          {newsletters.map(({ _id, featuredImage, publishDate, title, slug }) => (
            <MediaCard
              key={_id}
              featuredImage={featuredImage.asset}
              date={publishDate}
              dateOptions={{
                month: "long",
                year: "numeric",
              }}
              title={title}
              linkTo={`/newsletters/${slug.current}/`}
            />
          ))}
        </div>
      </main>
      <Footer />
    </>
  );
}

export const query = graphql`
  query NewslettersPageQuery {
    allSanityNewsletter(sort: { fields: publishDate, order: DESC }) {
      nodes {
        _id
        title
        slug {
          current
        }
        publishDate
        featuredImage {
          asset {
            gatsbyImageData(aspectRatio: 1.5)
          }
        }
      }
    }
  }
`;
